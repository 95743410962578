import { Controller } from 'stimulus'

export default class extends Controller {

  toggle(e) {
    e.preventDefault()
    if ( this.element.classList.contains('is-open') ) {
      this.element.classList.remove('is-open')
    } else {
      this.element.classList.add('is-open')
    }
  }
}
