import { Controller } from 'stimulus'
import Calendar from 'tui-calendar'
import { DateTime } from 'luxon'
import { theme } from '../config/tailwind.config'

export default class extends Controller {
  static targets = [ 'calendar' ]

  connect() {
    this.schedules = JSON.parse(this.element.dataset.schedules).map(schedule => {
      schedule.category = 'time'
      schedule.calendarId = 'default'
      return schedule
    })
    this.calendar = new Calendar(this.calendarTarget, {
      defaultView: 'week',
      taskView: false,
      isReadOnly: true,
      usageStatistics: false,
      scheduleView: ['time'],
      week: {
        hourStart: this.earliestHour(this.schedules, 9),
        hourEnd: this.latestHour(this.schedules, 16),
        daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        startDayOfWeek: 1,
        narrowWeekend: false,
      },
      theme: this.theme,
      template: {
        timegridDisplayPrimaryTime: (time) => {
          return `${time.hour}h`
        },
        timegridCurrentTime: () => {
          return null
        },
        time: (schedule) => {
          const startTime = DateTime.fromJSDate(schedule.start.toDate())
          const endTime = DateTime.fromJSDate(schedule.end.toDate())
          return `
            <div class="text-sm leading-tight">${schedule.title}</div>
            <div class="text-2xs mt-1">${startTime.toFormat('HH:mm')} à ${endTime.toFormat('HH:mm')}</div>
            <div class="text-2xs mt-1">${schedule.body}</div>
          `
        },
      },
      calendars: [
        {
          id: 'default',
          color: theme.colors.light[900],
          bgColor: theme.colors.secondary[500],
          borderColor: 'transparent'
        },
      ],
      timezone: {
        zones: [{timezoneName: 'Europe/Paris'}]
      }
    })
    this.calendar.setDate(new Date(this.element.dataset.date))
    this.calendar.createSchedules(this.schedules)
    this.calendar.on('clickSchedule', e => {
      const modalEvent = new Event('modal')
      modalEvent.modalId = `modal-${e.schedule.id}`
      document.dispatchEvent(modalEvent)
    })
  }

  disconnect() {
    this.calendar.destroy()
  }

  earliestHour(schedules, min) {
    const startHours = schedules.map(schedule => DateTime.fromISO(schedule.start).setZone('Europe/Paris').plus({ hours: -1 }).toFormat('H'))
    return Math.min(min, ...startHours)
  }

  latestHour(schedules, max) {
    const endHours = schedules.map(schedule => DateTime.fromISO(schedule.end).setZone('Europe/Paris').plus({ hours: 1 }).toFormat('H'))
    return Math.max(max, ...endHours)
  }

  theme = {
    'common.border': '0',
    'common.backgroundColor': 'transparent',
    'common.holiday.color': theme.colors.dark[700],
    'common.saturday.color': theme.colors.dark[700],
    'common.dayname.color': theme.colors.dark[900],
    'common.today.color': theme.colors.dark[900],

    // week header 'dayname'
    'week.dayname.backgroundColor': theme.colors.light[900],
    'week.today.color': theme.colors.primary[500],
    'week.pastDay.color': theme.colors.dark[500],

    // week daygrid 'daygrid'

    'week.daygridLeft.width': '80px',

    'week.today.backgroundColor': 'transparent',
    'week.weekend.backgroundColor': 'transparent',

    // week timegrid 'timegrid'
    'week.timegridLeft.width': '48px',
    'week.timegridLeft.backgroundColor': 'transparent',
    'week.timegridLeft.borderRight': '1px solid #e5e5e5',
    'week.timegridLeft.fontSize': '11px',
    'week.timegridLeftTimezoneLabel.height': '40px',

    'week.timegridOneHour.height': '120px',
    'week.timegridHalfHour.height': '60px',
    'week.timegridHalfHour.borderBottom': 'none',
    'week.timegridHorizontalLine.borderBottom': '1px solid rgba(0, 0, 0, .1)',

    'week.timegrid.paddingRight': '0',
    'week.timegrid.borderRight': '0',
    'week.timegridSchedule.borderRadius': '12px',
    'week.timegridSchedule.paddingLeft': '0',

    'week.currentTime.color': theme.colors.dark[700],
    'week.currentTime.fontSize': '10px',
    'week.currentTime.fontWeight': '600',

    'week.pastTime.color': theme.colors.dark[700],
    'week.pastTime.fontWeight': '600',

    'week.futureTime.color': theme.colors.dark[700],
    'week.futureTime.fontWeight': '600',

    'week.currentTimeLinePast.border': '0',
    'week.currentTimeLineBullet.backgroundColor': 'transparent',
    'week.currentTimeLineToday.border': '0',
    'week.currentTimeLineFuture.border': '0',

    'week.timegridOneHour.height': '100px',
  }
}
