import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'drawer', 'active' ]
  static classes = [ 'open' ]

  initialize() {
    this.openingSpeed = this.element.dataset.expandableSpeed || 400
    if ( this.hasActiveTarget ) {
      if ( this.hasOpenClass ) this.element.classList.add(this.openClass)
      this.drawerTarget.style.removeProperty('display')
    } else {
      this.drawerTarget.style.display = 'none'
    }
  }

  toggle(e) {
    e.preventDefault()
    if ( window.getComputedStyle(this.drawerTarget).display === 'none' ) {
      this.open(this.drawerTarget, this.openingSpeed)
    } else {
      this.close(this.drawerTarget, this.openingSpeed)
    }
  }

  close(target, duration) {
    if ( this.hasOpenClass ) this.element.classList.remove(this.openClass)
    target.style.transitionProperty = 'height, margin, padding, opacity'
    target.style.transitionDuration = duration + 'ms'
    target.style.height = target.offsetHeight + 'px'
    target.offsetHeight
    this.resetProperties(target)
    window.setTimeout( () => {
      target.style.display = 'none'
      this.removeProperties(target, ['height', 'padding-top', 'padding-bottom', 'margin-top', 'margin-bottom', 'opacity', 'overflow', 'transition-duration', 'transition-property'])
    }, duration)
  }

  open(target, duration) {
    if ( this.hasOpenClass ) this.element.classList.add(this.openClass)
    target.style.removeProperty('display')
    let display = window.getComputedStyle(target).display
    if (display === 'none') display = 'block'
    target.style.display = display
    const height = target.offsetHeight
    this.resetProperties(target)
    target.offsetHeight
    target.style.transitionProperty = "height, margin, padding, opacity"
    target.style.transitionDuration = duration + 'ms'
    target.style.height = height + 'px'
    this.removeProperties(target, ['padding-top', 'padding-bottom', 'margin-top', 'margin-bottom', 'opacity'])
    window.setTimeout( () => {
      this.removeProperties(target, ['height', 'overflow', 'transition-duration', 'transition-property'])
    }, duration)
  }

  resetProperties(element) {
    element.style.overflow = 'hidden'
    element.style.height = 0
    element.style.paddingTop = 0
    element.style.paddingBottom = 0
    element.style.marginTop = 0
    element.style.marginBottom = 0
    element.style.opacity = 0
  }

  removeProperties(element, properties) {
    for (const property of properties) {
      element.style.removeProperty(property)
    }
  }
}
