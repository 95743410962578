import Sortable from "stimulus-sortable";

const updateSortedLinks = (sortables, templateUrlAttributeName) => {
  Array.from(sortables).forEach((sortable, index) => {
    Array.from(
      sortable.querySelectorAll(`[${templateUrlAttributeName}]`)
    ).forEach((link) => {
      link.setAttribute(
        "href",
        link
          .getAttribute(templateUrlAttributeName)
          .replace("PLACEHOLDER", index)
      );
    });
  });
};

export default class extends Sortable {
  static targets = ["sortable"];

  end({ item, newIndex }) {
    // FIXME: this runs an ajax query, but simulus-sortable does not give us a hook to react in case of errors
    // Hopefully this won't happen as the controller only updates position attributes
    super.end({ item, newIndex });
    updateSortedLinks(this.sortableTargets, "data-new-brick-template-url");
  }
}
