import { Controller } from 'stimulus'
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = [ 'box', 'reference' ]
  static classes = [ 'container' ]

  connect() {
    this.popper = this.initPopper(this.referenceTarget)
  }

  disconnect() {
    this.popper.destroy()
  }

  toggle(e) {
    e.preventDefault()
    this.isOpen ? this.close() : this.open()
  }

  open() {
    this.popper.update()
    this.boxTarget.classList.add('is-open')
    this.isOpen = true
  }

  close(e) {
    if ( e && this.element.contains(e.target) && this.isOpen ) return
    this.boxTarget.classList.remove('is-open')
    this.isOpen = false
  }

  initPopper(reference) {
    this.container = document.createElement('div')
    if ( this.hasContainerClass ) this.container.classList.add(...this.containerClass.split(' '))
    this.element.appendChild(this.container)
    this.container.appendChild(this.boxTarget)
    return createPopper(
      reference,
      this.container,
      {
        placement: 'bottom-start',
      }
    )
  }
}
