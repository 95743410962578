import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'active' ]

  connect() {
    this.element.scroll({
      left: this.activeTarget.offsetLeft
    })
  }
}
