import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'anchor' ]

  connect() {
    if ( this.hasAnchorTarget ) {
      this.element.scrollTo({
        top: this.anchorTarget.offsetTop,
      })
    }
  }
}
