import { Controller } from "stimulus";
import html2canvas from 'html2canvas';

export default class extends Controller {
  static targets = ["canvas"];

  capture(){
    html2canvas(this.canvasTarget, {
      allowTaint: true,
      useCORS:true
    }).then(canvas => {
      let title = this.canvasTarget.dataset.activityName + ".png"
      var imgBase64 = canvas.toDataURL();
      // console.log("imgBase64:", imgBase64);
      var imgURL = "data:image/" + imgBase64;
      var triggerDownload = $("<a>").attr("href", imgURL).attr("download", title).appendTo("body");
      triggerDownload[0].click();
      triggerDownload.remove();
    });
  }
}
