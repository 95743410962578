const colors = require('./tailwind.theme.colors.json')
const screens = require('./tailwind.theme.screens.json')
const font = require('./tailwind.theme.font.json')

module.exports = {
  purge: {
    enabled: ['production', 'staging'].includes(process.env.RAILS_ENV),
    mode: 'all',
    content: [
      './app/frontend/controllers/**/*.js',
      './app/helpers/**/*.rb',
      './app/views/**/*.slim',
    ],
    options: {
      safelist: [/[^data-]/],
    },
  },
  theme: {
    screens,
    colors,
    ...font,
    container: {
      center: true,
      padding: '1.5rem',
    },
    borderRadius: {
      none: '0px',
      xs: '0.375rem', // 6px
      sm: '0.5rem', // 8px
      DEFAULT: '0.75rem', // 12px
      lg: '1rem', // 16px
      xl: '1.5rem', // 24px
      '2xl': '2rem', // 32px
      full: '9999px',
    },
    boxShadow: theme => ({
      sm: `0 1px 1px 0 ${theme('colors.dark.400')}, 0 0 1px 0 ${theme('colors.dark.400')}`,
      DEFAULT: `0 2px 3px -1px ${theme('colors.dark.400')}, 0 0 1px 0 ${theme('colors.dark.400')}`,
      lg: `0 4px 6px -1px ${theme('colors.dark.400')}, 0 0 1px 0 ${theme('colors.dark.400')}`,
      xl: `0 12px 16px -1px ${theme('colors.dark.400')}, 0 0 1px 0 ${theme('colors.dark.400')}`,
      '2xl': `0 28px 40px -2px ${theme('colors.dark.400')}, 0 0 1px 0 ${theme('colors.dark.400')}`,
      none: 'none',
    }),
    extend: {
      borderColor: (theme) => ({
        DEFAULT: theme('colors.dark.400'),
      }),
      inset: {
        6: '1.5rem',
        24: '6rem',
      },
      maxWidth: {
        '8xl': '100rem',
      },
      minHeight: {
        '3xl':	'48rem',
      },
      minWidth: {
        '3xl':	'48rem',
      },
      order: {
        '-1': '-1',
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            color: theme('colors.dark.800'),
            lineHeight: 1.5,
            overflowWrap: 'break-word',
            a: {
              color: theme('colors.secondary.500'),
              transitionDuration: theme('transitionDuration.DEFAULT'),
              transitionProperty: theme('transitionProperty.DEFAULT'),
              transitionTimingFunction: theme('transitionTimingFunction.DEFAULT'),
            },
            'a:hover': {
              color: theme('colors.primary.500'),
            },
            strong: {
              color: theme('colors.dark.900'),
              fontWeight: theme('fontWeight.semibold'),
            },
            p: {
              marginTop: '.5em',
              marginBottom: '.5em',
            },
            h1: {
              fontFamily: theme('fontFamily.secondary'),
              fontSize: theme('fontSize.xl')[0],
              lineHeight: theme('fontSize.xl')[1],
              fontWeight: theme('fontWeight.semibold'),
              color: theme('colors.dark.900'),
            },
            h2: {
              fontFamily: theme('fontFamily.secondary'),
              fontSize: theme('fontSize.lg')[0],
              lineHeight: theme('fontSize.lg')[1],
              fontWeight: theme('fontWeight.semibold'),
              color: theme('colors.dark.900'),
            },
            h3: {
              fontFamily: theme('fontFamily.secondary'),
              fontSize: theme('fontSize.base')[0],
              lineHeight: theme('fontSize.base')[1],
              fontWeight: theme('fontWeight.semibold'),
              color: theme('colors.dark.900'),
            },
            h4: {
              fontFamily: theme('fontFamily.secondary'),
              fontSize: theme('fontSize.sm')[0],
              lineHeight: theme('fontSize.sm')[1],
              fontWeight: theme('fontWeight.semibold'),
              color: theme('colors.dark.900'),
            },
            blockquote: {
              color: theme('colors.dark.900'),
            },
            code: {
              backgroundColor: theme('colors.primary.100'),
              color: theme('colors.primary.500'),
              fontWeight: theme('fontWeight.normal'),
              padding: '0.125rem 0.25rem',
              borderRadius: '0.25rem',
            },
            'code::before': {
              content: null,
            },
            'code::after': {
              content: null,
            },
            blockquote: {
              borderLeftColor: theme('colors.dark.500'),
            },
            'ul > li::before': {
              backgroundColor: theme('colors.dark.500'),
            },
            'ol > li::before': {
              color: theme('colors.dark.600'),
              fontSize: theme('fontSize.sm')[0],
              fontWeight: theme('fontWeight.semibold'),
            },
          },
        },
        light: {
          css: {
            color: theme('colors.light.900'),
            a: {
              color: theme('colors.light.800'),
            },
            'a:hover': {
              color: theme('colors.light.700'),
            },
            strong: {
              color: theme('colors.light.900'),
            },
            h1: {
              color: theme('colors.light.900'),
            },
            h2: {
              color: theme('colors.light.900'),
            },
            h3: {
              color: theme('colors.light.900'),
            },
            h4: {
              color: theme('colors.light.900'),
            },
            blockquote: {
              color: theme('colors.light.900'),
              borderLeftColor: theme('colors.light.600'),
            },
            'ul > li::before': {
              backgroundColor: theme('colors.light.600'),
            },
            'ol > li::before': {
              color: theme('colors.light.800'),
            },
          },
        },
        sm: {
          css: {
            fontSize: theme('fontSize.sm')[0],
            fontWeight: theme('fontWeight.medium'),
            h1: {
              fontSize: theme('fontSize.lg')[0],
              lineHeight: theme('fontSize.lg')[1],
            },
            h2: {
              fontSize: theme('fontSize.base')[0],
              lineHeight: theme('fontSize.base')[1],
            },
            h3: {
              fontSize: theme('fontSize.sm')[0],
              lineHeight: theme('fontSize.sm')[1],
            },
            h4: {
              fontSize: theme('fontSize.xs')[0],
              lineHeight: theme('fontSize.xs')[1],
            },
          },
        },
      }),
    },
  },
  variants: {
  },
  plugins: [
    require('@tailwindcss/typography'),
  ],
  corePlugins: {
    backgroundOpacity: false,
    gap: false,
    columnGap: false,
    rowGap: false,
    gridAutoColumns: false,
    gridAutoFlow: false,
    gridAutoRows: false,
    gridColumn: false,
    gridColumnEnd: false,
    gridColumnStart: false,
    gridRow: false,
    gridRowEnd: false,
    gridRowStart: false,
    gridTemplateColumns: false,
    gridTemplateRows: false,
    rotate: false,
    scale: false,
    skew: false,
    space: false,
    textOpacity: false,
    transform: false,
    transformOrigin: false,
    translate: false,
  }
}
