import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  connect() {
    this.multiple = this.element.hasAttribute('multiple')
    this.slimSelect = new SlimSelect({
      select: this.element,
      showSearch: this.element.dataset.search ?? false,
      searchPlaceholder: this.element.dataset.search,
      searchText: this.element.dataset.searchEmpty,
      allowDeselectOption: true,
      searchHighlight: true,
      closeOnSelect: !this.multiple,
      showContent: 'down'
    })
  }

  disconnect() {
    this.slimSelect.destroy()
  }
}
