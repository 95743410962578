import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    // Button added in JS to avoid showing it if there is an error in JS
    this.closeButton = document.createElement('button')
    this.closeButton.classList.add('flash__button')
    this.closeButton.dataset.action = 'click->flash#close'
    this.element.appendChild(this.closeButton)

    this.duration = this.element.dataset.duration
    if ( this.duration ) {
      setTimeout(() => {
        this.close()
      }, parseInt(this.duration))
    }
  }

  disconnect() {
    this.closeButton.remove()
    delete this.closeButton
    this.element.removeEventListener('transitionend', this.element.remove)
  }

  close() {
    this.element.classList.add('is-hiding')
    this.element.addEventListener('transitionend', this.element.remove)
  }
}
