import { Controller } from 'stimulus'
import Quill from 'quill'

export default class extends Controller {
  static targets = [ 'content' ]

  initialize() {
    this.customizeIcons()
  }

  connect() {
    this.textarea = document.createElement('div')
    this.element.appendChild(this.textarea)
    this.editor = new Quill(this.textarea, {
      modules: {
        toolbar: [
          [{ header: 2 }, { header: 3 }],
          ['bold', 'italic', 'underline'],
          ['link'],
          ['blockquote'],
          [{ list: 'bullet' }, { list: 'ordered' }],
        ],
      },
      placeholder: this.element.getAttribute('placeholder'),
      theme: 'snow',
    })
    this.editor.on('text-change', (e) => {
      this.contentTarget.value = this.editor.root.innerHTML
    })
    const delta = this.editor.clipboard.convert(this.contentTarget.value)
    this.editor.setContents(delta)
    this.editor.root.classList.add('prose')
  }

  disconnect() {
    this.textarea.remove()
    this.editor.theme.modules.toolbar.container.remove()
    delete this.textarea
    delete this.editor
  }

  customizeIcons() {
    const icons = Quill.import('ui/icons')
    icons['header'][2] = '<svg viewBox="0 0 18 18"><path d="M5.184,5.288L2.491,5.288C2.194,5.288 1.945,5.197 1.745,5.014C1.545,4.831 1.445,4.591 1.445,4.294C1.445,3.997 1.545,3.759 1.745,3.582C1.945,3.405 2.194,3.316 2.491,3.316L10.105,3.316C10.402,3.316 10.651,3.408 10.851,3.591C11.051,3.774 11.151,4.014 11.151,4.311C11.151,4.608 11.051,4.845 10.851,5.023C10.651,5.2 10.402,5.288 10.105,5.288L7.413,5.288L7.413,14.274C7.413,14.571 7.307,14.82 7.096,15.02C6.884,15.22 6.613,15.32 6.281,15.32C5.961,15.32 5.698,15.22 5.492,15.02C5.286,14.82 5.184,14.571 5.184,14.274L5.184,5.288ZM15.909,13.647L14.831,13.647L14.831,8.037C14.834,8.014 14.835,7.991 14.835,7.968C14.835,7.671 14.591,7.427 14.294,7.427C14.271,7.427 14.248,7.428 14.226,7.431L13.598,7.431C13.319,7.422 13.048,7.53 12.85,7.728L11.486,9.004C11.347,9.107 11.265,9.269 11.265,9.442C11.265,9.605 11.338,9.76 11.464,9.863L11.75,10.17C11.851,10.31 12.014,10.394 12.187,10.394C12.345,10.394 12.495,10.324 12.597,10.204L12.696,10.116C12.797,10.013 12.879,9.894 12.938,9.763L12.96,9.763C12.96,9.763 12.949,10.072 12.949,10.369L12.949,13.647L11.882,13.647C11.859,13.644 11.836,13.643 11.813,13.643C11.517,13.643 11.272,13.887 11.272,14.183L11.277,14.692C11.274,14.715 11.272,14.738 11.272,14.761C11.272,15.058 11.517,15.302 11.813,15.302C11.836,15.302 11.859,15.3 11.882,15.297L15.909,15.297C15.931,15.3 15.954,15.302 15.977,15.302C16.274,15.302 16.518,15.058 16.518,14.761L16.514,14.252C16.517,14.23 16.518,14.207 16.518,14.183C16.518,13.887 16.274,13.643 15.977,13.643C15.954,13.643 15.931,13.644 15.909,13.647Z"/></svg>'
    icons['header'][3] = '<svg viewBox="0 0 18 18"><path d="M15.868,14.271L15.872,14.779C15.872,15.076 15.628,15.32 15.331,15.32C15.308,15.32 15.285,15.319 15.263,15.316L10.983,15.316C10.961,15.318 10.939,15.319 10.917,15.319C10.597,15.319 10.333,15.056 10.333,14.736L10.334,14.469C10.334,11.564 13.733,11.047 13.733,9.914C13.733,9.912 13.733,9.909 13.733,9.907C13.733,9.481 13.383,9.13 12.957,9.13C12.933,9.13 12.91,9.131 12.886,9.133C12.567,9.143 12.265,9.282 12.05,9.518C11.775,9.782 11.489,9.892 11.192,9.65L10.763,9.309C10.455,9.067 10.378,8.791 10.609,8.494C11.188,7.729 12.103,7.29 13.062,7.318C13.139,7.31 13.216,7.307 13.293,7.307C14.626,7.307 15.728,8.394 15.747,9.727C15.747,12.18 12.567,12.654 12.468,13.665L15.263,13.665C15.285,13.662 15.308,13.661 15.331,13.661C15.628,13.661 15.872,13.905 15.872,14.202C15.872,14.225 15.871,14.248 15.868,14.271ZM4.501,5.288L1.808,5.288C1.511,5.288 1.263,5.197 1.063,5.014C0.862,4.831 0.762,4.591 0.762,4.294C0.762,3.997 0.862,3.759 1.063,3.582C1.263,3.405 1.511,3.316 1.808,3.316L9.422,3.316C9.72,3.316 9.968,3.408 10.168,3.591C10.368,3.774 10.468,4.014 10.468,4.311C10.468,4.608 10.368,4.845 10.168,5.023C9.968,5.2 9.72,5.288 9.422,5.288L6.73,5.288L6.73,14.274C6.73,14.571 6.624,14.82 6.413,15.02C6.201,15.22 5.93,15.32 5.598,15.32C5.278,15.32 5.015,15.22 4.809,15.02C4.604,14.82 4.501,14.571 4.501,14.274L4.501,5.288Z"/></svg>'
  }
}
