import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'modal' ]

  connect() {
    document.addEventListener('modal', e => this.openModal(e.modalId))
  }

  disconnect() {
    document.removeEventListener('modal', e => this.openModal(e.modalId))
  }

  openModal(modalId) {
    this.closeAllModals()
    this.currentModal = this.modalTargets.find(modal => modal.id === modalId)
    if ( this.currentModal ) this.currentModal.classList.remove('hidden')
    this.element.classList.add('visible', 'opacity-100')
  }

  closeAllModals() {
    this.modalTargets.map(modal => modal.classList.add('hidden'))
  }

  close(event) {
    event.preventDefault();
    this.element.classList.remove('visible', 'opacity-100')
    this.closeAllModals()
  }

  outside(e) {
    if ( e.target.contains(this.modalTarget) ) {
      this.close()
    }
  }

  displayInModal(e) {
    e.preventDefault()
    e.stopPropagation()

    const modalEvent = new Event('modal')
    modalEvent.modalId = 'js-main-modal'
    document.dispatchEvent(modalEvent)

    const container = document.querySelector(`#${modalEvent.modalId} .js-container`)
    const [, , xhr] = event.detail
    container.innerHTML = xhr.response;
  }
}
