export const createHiddenInput = (document, name, value = "") => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = name;
  input.value = value;
  return input;
};
export const insertAfter = (element, reference) => {
  reference.parentNode.insertBefore(element, reference.nextSibling);
};
