import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        const inputs = this.element.querySelectorAll("input")
        inputs.forEach((input) => {
            input.addEventListener('click', (e) => {
                e.preventDefault()
            })
        })
    }
}
