import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'button', 'input', 'name', 'selection' ]

  connect() {
  }

  upload() {
    if ( this.inputTarget.files.length === 0 ) {
      this.reset()
    } else {
      this.buttonTarget.classList.add('hidden')
      this.selectionTarget.classList.remove('hidden')
      this.nameTarget.textContent = this.inputTarget.files.length === 1 ? this.inputTarget.files[0].name : `${this.inputTarget.files.length} files`
    }
  }

  reset() {
    this.inputTarget.value = ''
    this.buttonTarget.classList.remove('hidden')
    this.selectionTarget.classList.add('hidden')
  }
}
