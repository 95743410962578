import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileinput"];

  fileInputChanged(){
    this.fileinputTarget.form.elements.commit.click();
  }

}
