import { Controller } from 'stimulus'
import SignaturePad from 'signature_pad'

export default class extends Controller {
    static targets = ['canvas', 'input']

    connect() {
        const signaturePad = new SignaturePad(this.canvasTarget);
        signaturePad.addEventListener("endStroke", () => {
            this.inputTarget.value = signaturePad.toDataURL();
        })
    }
}