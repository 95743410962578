import { Controller } from 'stimulus'
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  connect() {
    this.popper = this.initPopper(this.element)
    this.element.addEventListener('mouseenter', () => this.open())
    this.element.addEventListener('mouseleave', () => this.close())
    this.element.addEventListener('focus', () => this.open())
    this.element.addEventListener('blur', () => this.close())
    this.element.addEventListener('click', () => this.open(true))
    document.addEventListener('click', (e) => this.close(e))
  }

  disconnect() {
    this.popper.destroy()
    this.container.remove()
    this.element.removeEventListener('mouseenter', () => this.open())
    this.element.removeEventListener('mouseleave', () => this.close())
    this.element.removeEventListener('focus', () => this.open())
    this.element.removeEventListener('blur', () => this.close())
    this.element.removeEventListener('click', () => this.open(true))
    document.removeEventListener('click', (e) => this.close(e))
  }

  open(keepOpen) {
    this.keepOpen = keepOpen
    this.popper.update()
    this.tooltip.classList.add('is-open')
    this.isOpen = true
  }

  close(e) {
    if ( !this.isOpen ) return
    if ( !e && this.keepOpen ) return
    if ( e && e.path.includes(this.element) ) return
    this.tooltip.classList.remove('is-open')
    this.keepOpen = false
    this.isOpen = false
  }

  initPopper(reference) {
    this.container = document.createElement('div')
    this.tooltip = document.createElement('div')
    this.tooltip.classList.add('tooltip')
    this.tooltip.textContent = this.element.dataset.tooltip
    this.arrow = document.createElement('div')
    this.arrow.dataset.popperArrow = true
    this.tooltip.appendChild(this.arrow)
    this.container.appendChild(this.tooltip)
    this.insertAfter(this.container, reference)
    return createPopper(
      reference,
      this.container,
      {
        placement: this.element.dataset.placement ?? 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 12],
            },
          },
        ]
      }
    )
  }

  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
  }
}
