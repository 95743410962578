import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import axios from "axios";
import { createHiddenInput, insertAfter } from "../utils/dom";
import { hide, display } from "../utils/visibility";
import { template } from "lodash"

class FileUploadRecord {
  constructor(file, fileUploadUrl, directUploadUrl, delegate) {
    this.file = file;
    this.fileUploadUrl = fileUploadUrl;
    this.directUpload = new DirectUpload(file, directUploadUrl, delegate);
  }

  create(callback) {
    const element = document.head.querySelector('meta[name="csrf-token"]');
    let csrfToken;
    if (element) {
      csrfToken = element.getAttribute("content");
    }
    this.directUpload.create((error, attributes) => {
      if (error) {
        callback(error);
      } else {
        axios({
          method: "post",
          url: this.fileUploadUrl,
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          data: {
            blob_signed_id: attributes.signed_id,
          },
        })
          .then(function (response) {
            callback(null, response.data);
          })
          .catch(function (error) {
            callback(error);
          });
      }
    });
  }
}
export default class extends Controller {
  static targets = ["file", "hiddenInputTemplate", "fileLabelTemplate", "uploadButton"];

  connect() {
    this.form = this.element.closest("form");
    this.form.addEventListener("submit", this.didSubmit.bind(this));
  }

  didSubmit() {
    if (
      !this.form.querySelector(
        `input[type="hidden"][name="${this.fileTarget.name}"]`
      )
    ) {
      // We create an invalid input as a hack to workaround to ActiveRecord not
      // validating file_upload_ids attribute when an empty array is provided
      // this invalid id will fail file_upload validation cause no file upload with this id exists
      const hiddenInput = createHiddenInput(
        document,
        this.fileTarget.name,
        "invalid"
      );
      insertAfter(hiddenInput, this.fileTarget);
    }
    this.fileTarget.disabled = true;
  }

  disconnect() {
    this.form.removeEventListener("submit", this.didSubmit.bind(this));
    this.fileTarget.disabled = false;
  }

  uploadFile(_event, isSingle) {
    const files = this.fileTarget.files;
    const directUploadUrl = this.fileTarget.dataset.documentUploadDirectUrl;
    // FIXME not yet available
    const fileUploadUrl = this.fileTarget.dataset.documentUploadFileUrl;

    [...files].forEach((file) => {
      // By passing self, we can receive notifications from ActiveStorage by implementing
      // directUploadWillStoreFileWithXHR and directUploadWillStoreFileWithXHR methods
      new FileUploadRecord(file, fileUploadUrl, directUploadUrl, self).create(
        (error, attributes) => {
          if (error) {
            // FIXME display error
          } else {
            const hiddenInput = createHiddenInput(
              document,
              this.fileTarget.name,
              attributes.id
            );

            const fileLabelTemplate = template(this.fileLabelTemplateTarget.innerHTML);
            const templateData = {
              url: attributes.url,
              name: file.name
            };

            const fileLabel = document.createElement('div');
            fileLabel.innerHTML = fileLabelTemplate(templateData);
            fileLabel.append(hiddenInput);
            insertAfter(fileLabel, this.fileTarget);

            if (this.fileTarget.dataset.singleUpload) {
              hide(this.uploadButtonTarget);
            }
          }
        }
      );
    });
  }

  removeFile(event) {
    event.preventDefault();
    const fileContainer = event.currentTarget.parentNode;
    this.fileTarget.value = null;
    fileContainer.remove();

    if (this.fileTarget.dataset.singleUpload) {
      display(this.uploadButtonTarget);
    }
  }

  // TODO: progress notification hooks
  directUploadWillCreateBlobWithXHR(xhr) {}
  directUploadWillStoreFileWithXHR(xhr) {}
}
