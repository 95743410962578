import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'comment', 'rightAnswer', 'checkboxes' ]

  correct(event) {
    this.disableInputs(event.target);
    this.markAsWrong(event.target)
    this.displayComment();
    this.displayRightAnswer();
  }

  displayComment() {
    this.commentTarget.classList.remove('hidden');
  }

  displayRightAnswer() {
    this.rightAnswerTarget.classList.remove('hidden');
  }

  markAsWrong(input) {
    let wrongIcon = input.parentElement.querySelector('.js-wrong-answer')
    if (wrongIcon) {
      wrongIcon.classList.remove('hidden');
    }
  }

  disableInputs(selectedInput) {
    this.checkboxesTarget.querySelectorAll('input').forEach(function(input){
      if (selectedInput != input) {
        input.disabled = true;
      }
    })
  }

  replicateChoices(event) {
    event.target.innerText = null;

    document.querySelectorAll('.js-choice').forEach(function(input){
      let option = document.createElement("option");
      option.value = input.value;
      option.text = input.value;
      event.target.add(option, null);
    })
  }
}
