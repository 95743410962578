import { Controller } from 'stimulus'

export default class extends Controller {
  duplicate(event) {
    const currentTarget = event.currentTarget
    if ([1, 2, 3].some(v => currentTarget.id.includes(v))) {
      document.getElementById(currentTarget.id.replace('start', 'end')).value = currentTarget.value
    }
    if (currentTarget.id.includes(4)) {
      document.getElementById(currentTarget.id.replace('start', 'end')).value = currentTarget.value
    }
  }
}
