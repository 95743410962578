import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["anchor", "template", "answer"];

  initialize() {
    this.wrapperSelector = ".simple-nested-fields-wrapper";
  }

  customizeFormElement() {
    let formElement = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    if (this.answerTargets) {
      formElement = formElement.replace(/ANSWER_INDEX/g, this.answerTargets.length+1)
    }
    this.anchorTarget.insertAdjacentHTML("beforeBegin", formElement);
  }

  add(e) {
    e.preventDefault();
    this.customizeFormElement();
  }

  remove(e) {
    e.preventDefault();

    const wrapper = e.target.closest(this.wrapperSelector);
    wrapper.remove();
  }
}
