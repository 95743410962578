import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    addEventListener("turbo:click", event => {
      if(/contributors\/activities\/\d+\/\w+\/new$/.test(event.detail.url)) {
        document.scrollingElement.scrollTo(0, 100000);
      }
    });
  }
}
