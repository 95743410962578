export const display = (element) => {
  element.classList.remove('hidden')
};

export const hide = (element) => {
  element.classList.add('hidden')
};

export const displayCollection = (collection) => {
  collection.forEach((element) => {
    display(element)
  })
};

export const hideCollection = (collection) => {
  collection.forEach((element) => {
    hide(element)
  })
};
