import { Controller } from 'stimulus'
import { display, hide, displayCollection, hideCollection } from "../utils/visibility";

export default class extends Controller {

  static targets = [ 'submit', 'checkbox', 'discardedStep', 'editButton', 'cancelButton' ]

  edit() {
    display(this.submitTarget)
    displayCollection(this.checkboxTargets)
    displayCollection(this.discardedStepTargets)
    hide(this.editButtonTarget)
    display(this.cancelButtonTarget)
  }

  cancel() {
    hide(this.submitTarget)
    hideCollection(this.checkboxTargets)
    hideCollection(this.discardedStepTargets)
    display(this.editButtonTarget)
    hide(this.cancelButtonTarget)
  }
}
