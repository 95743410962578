import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {

  call(e) {
    const url = this.element.dataset.completeUrl;
    if(url) {
      axios.put(url, null, {headers: {"X-CSRF-Token": this.getMetaValue("csrf-token")}})
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }
}
