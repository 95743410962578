import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "@stimulus/polyfills"
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Reveal from "stimulus-reveal-controller"
import Sortable from "stimulus-sortable";
import "@hotwired/turbo-rails";

export const setup = (...contexts) => {
  Rails.start();

  ActiveStorage.start();

  const application = Application.start();
  contexts.forEach((context) => {
    application.load(definitionsFromContext(context));
  });
  application.register("reveal", Reveal)
  application.register("sortable", Sortable);

  require.context("images/", true, /\.(gif|jpg|png|svg)$/i);
  require.context("icons", true, /\.svg$/i);
};
